import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import { SubscribedPortfolio } from '../../../../types/subscribedPortfolio';
import { OwnedSharesTable } from './OwnedSharesTable';
import { MTabPanel } from '../../../../components/TabPanel';
import { WalkTable } from './WalkTable';
import { RestoreAggregatedStatisticsDialog } from './RestoreAggregatedStatisticsDialog';
import PortfolioPositionsTable from './PortfolioPositionsTable';
import { useFetchSubscribedPortfolioByUserIdQuery } from '../../../../redux/features/subscriberPortfolio/subscriberPortfolio.apiSlice';
import { useFetchUserIndexFundsQuery } from '../../../../redux/features/userIndexFunds/userIndexFunds.apiSlice';

interface IndexFundsTabListProps {
  userId: number;
  subscribedPortfolio: SubscribedPortfolio;
}

const enum TABS {
  OWNED_SHARES = 'owned_shares',
  WALK_TABLE = 'walt_table',
  PORTFOLIO_POSITIONS = 'portfolio_positions',
}

export function IndexFundsTabList({ userId, subscribedPortfolio }: IndexFundsTabListProps) {
  const [selectedTab, setSelectedTab] = React.useState<string>(TABS.OWNED_SHARES);
  const [open, setOpen] = React.useState(false);
  const { data: portfolio } = useFetchSubscribedPortfolioByUserIdQuery(userId);
  const { data: userIndexFunds } = useFetchUserIndexFundsQuery(userId);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const onRestoreStatistics = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="alpaca fund orders"
        >
          <Tab value={TABS.OWNED_SHARES} label="Owned Shared" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab value={TABS.WALK_TABLE} label="Walk Table" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab
            value={TABS.PORTFOLIO_POSITIONS}
            label="Portfolio Positions"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
        </TabList>
      </Box>
      <MTabPanel value={TABS.OWNED_SHARES}>
        <OwnedSharesTable userId={userId} subscribedPortfolio={subscribedPortfolio} />
      </MTabPanel>
      <MTabPanel value={TABS.WALK_TABLE}>
        <WalkTable userId={userId} subscribedPortfolioId={subscribedPortfolio.id} openDialog={onRestoreStatistics} />
      </MTabPanel>
      <MTabPanel value={TABS.PORTFOLIO_POSITIONS}>
        <PortfolioPositionsTable portfolio={portfolio} userIndexFunds={userIndexFunds?.items || []} />
      </MTabPanel>
      <RestoreAggregatedStatisticsDialog
        open={open}
        onClose={handleClose}
        subscribedPortfolioId={subscribedPortfolio.id}
      />
    </TabContext>
  );
}
